<template>
  <div>
    <v-app-bar
      fixed
      app
      class="hidden-xs"
      :class="[{ 'app-bar-mobile': currentPage }]"
    >
      <Logo />
      <v-spacer></v-spacer>
      <router-link
        v-if="$route.name === 'register' || $route.name === 'reset-password'"
        to="login"
        class="register"
      >
        <p>Login</p>
      </router-link>
      <router-link v-else to="register" class="register">
        <p>Register</p>
      </router-link>
    </v-app-bar>
    <v-main>
      <v-container class="content-container">
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { SITE } from "@/config";
import Logo from "@/components/logo.vue";
export default {
  name: "Auth",
  components: { Logo },
  computed: {
    siteName() {
      return SITE.toLowerCase();
    },
    currentPage() {
      return !(
        this.$route.name == "register" ||
        this.$route.name == "reset-password" ||
        this.$route.name == "register-complete"
      );
    }
  }
};
</script>

<style scoped>
.content-container {
  width: 95%;
  margin: 15px auto;
  max-width: 950px;
}
.logo {
  padding: 5px;
  width: 310px;
}
.register {
  font-size: 20px;
  align-self: flex-end;
}

::v-deep .v-toolbar__content {
  justify-content: center;
  flex-wrap: wrap;
  max-width: 950px;
  margin: 0 auto;
  height: 96px !important;
  padding: 0;
  width: 95%;
}
::v-deep .v-app-bar {
  height: 96px !important;
  box-shadow: none !important;
  background-color: #fff !important;
}

.v-main {
  padding-top: 96px !important;
}
@media only screen and (max-width: 767px) {
  ::v-deep .v-toolbar__content {
    max-width: 650px;
  }
  .app-bar-mobile {
    height: 100px !important;
  }
  .main-mobile {
    padding: 100px 0 0 !important;
  }
  .logo {
    padding: 5px;
    width: 200px;
  }
}
</style>
